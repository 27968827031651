<template>
  <div class="information-layout">
    <MqResponsive group>
      <template #xs>
        <div class="information-layout__images">
          <div class="information-layout__images--peoples">
            <picture>
              <UiImgAgentSource
                :srcset="data.images.main_image.medium_webp"
                :srcset-agent="data.images.main_image_agent?.medium_webp"
                type="image/webp"
                :media="`(max-width: ${lg}px)`"
              />
              <UiImgAgentSource
                :srcset="data.images.main_image.large_webp"
                :srcset-agent="data.images.main_image_agent?.large_webp"
                type="image/webp"
                :media="`(min-width: ${lg}px)`"
              />
              <UiImgAgentSource
                :srcset="data.images.main_image.small_png"
                :srcset-agent="data.images.main_image_agent?.small_png"
                type="image/png"
                :media="`(max-width: ${sm}px)`"
              />
              <UiImgAgentSource
                :srcset="data.images.main_image.medium_png"
                :srcset-agent="data.images.main_image_agent?.medium_png"
                type="image/png"
                :media="`(max-width: ${lg}px)`"
              />
              <UiImgAgentSource
                :srcset="data.images.main_image.large_png"
                :srcset-agent="data.images.main_image_agent?.large_png"
                type="image/png"
                :media="`(min-width: ${lg}px)`"
              />
              <UiImgAgentMediaImage
                :src="data.images.main_image.medium_png"
                :src-agent="data.images.main_image_agent?.medium_png"
                :alt="data.extras.main_image_alt_attr"
                :title="data.title"
              />
            </picture>
          </div>
        </div>
      </template>
      <template #sm+>
        <div class="information-layout__1col animation__photo">
          <div class="information-layout__1col--image">
            <picture>
              <UiImgAgentSource
                :srcset="data.images.main_image.small_webp"
                :srcset-agent="data.images.main_image_agent?.small_webp"
                type="image/webp"
                :media="`(max-width: ${sm}px)`"
              />
              <UiImgAgentSource
                :srcset="data.images.main_image.medium_webp"
                :srcset-agent="data.images.main_image_agent?.medium_webp"
                type="image/webp"
                :media="`(max-width: ${lg}px)`"
              />
              <UiImgAgentSource
                :srcset="data.images.main_image.large_webp"
                :srcset-agent="data.images.main_image_agent?.large_webp"
                type="image/webp"
                :media="`(min-width: ${lg}px)`"
              />
              <UiImgAgentSource
                :srcset="data.images.main_image.small_png"
                :srcset-agent="data.images.main_image_agent?.small_png"
                type="image/png"
                :media="`(max-width: ${sm}px)`"
              />
              <UiImgAgentSource
                :srcset="data.images.main_image.medium_png"
                :srcset-agent="data.images.main_image_agent?.medium_png"
                type="image/png"
                :media="`(max-width: ${lg}px)`"
              />
              <UiImgAgentSource
                :srcset="data.images.main_image.large_png"
                :srcset-agent="data.images.main_image_agent?.large_png"
                type="image/png"
                :media="`(min-width: ${lg}px)`"
              />
              <UiImgAgentMediaImage
                :src="data.images.main_image.medium_png"
                :src-agent="data.images.main_image_agent?.medium_png"
                :alt="data.extras.main_image_alt_attr"
                :title="data.title"
                loading="lazy"
              />
            </picture>
          </div>

          <div v-if="data.images.left_corner_image_svg" :class="{'information-layout__1col--right-top-corner':!data.images.square_icon_svg,'information-layout__1col--left-corner':data.images.square_icon_svg,}">
            <UiImgAgentMediaImage
              :src="data.images.left_corner_image_svg?.svg ?? data.images.left_corner_image_svg?.small_png"
              :src-agent="data.images.left_corner_image_svg_agent?.svg ?? data.images.left_corner_image_svg_agent?.small_png"
              :alt="data.extras.left_corner_image_alt_attr"
            />
          </div>
          <div v-if="data.images.square_icon_svg" class="information-layout__1col--square">
            <UiImgAgentMediaImage
              :src="data.images.square_icon_svg?.svg ?? data.images.square_icon_svg?.small_png"
              :src-agent="data.images.square_icon_svg_agent?.svg ?? data.images.square_icon_svg_agent?.small_png"
              :alt="data.extras.left_corner_image_alt_attr"
            />
            <span class="text-secondary information-layout__1col--square-upper-text text-bold">
              {{ data.extras.square_upper_text }}
            </span>
            <div class="text-secondary information-layout__1col--square-lower-text">
              {{ data.extras.square_lower_text }}
            </div>
          </div>
        </div>
      </template>
    </MqResponsive>

    <div class="information-card">
      <div class="information-card__content animation__header">
        <h1 class="text-secondary text-geogrotesque text-bold">
          {{ data.title }}
        </h1>
      </div>
      <div class="information-card__content animation__header">
        <span class="text-bigger">{{ data.content }}</span>
      </div>
      <div class="information-card__button-link">
        <div class="information-card__button-link--element">
          <button
            class="button button--primary button--radius button--information-buy animation__button"
            @click="handleButtonClick"
          >
            <span
              class="text-bold"
              :class="{ 'text-wrap': data.extras.button_text && data.extras.button_text.length >= 10 }"
            >
              {{ data.extras.button_text }}
            </span>
          </button>
        </div>

        <div class="information-card__button-link--element">
          <RouterLink
            :to="data.extras.text_link_url"
            class="menu__link animation__button"
            :class="{ 'text-wrap': data.extras.text_link && data.extras.text_link.length >= 10 }"
          >
            {{ data.extras.text_link }}
          </RouterLink>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { HomepageSlider } from '~/types/cms';
import { useWindowWidth } from '~/composables';

interface Props {
  data: HomepageSlider;
}

const { sm, lg } = useWindowWidth();
const props = withDefaults(defineProps<Props>(), {});
const { data } = toRefs(props);
const { t } = useI18n();
const router = useRouter();

const handleButtonClick = () => {
  router.push(data.value.extras.button_url);
};
</script>
