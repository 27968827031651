import { computed } from 'vue-demi';
import axios from 'axios';
import dayjs from 'dayjs';

import { useBuyWizardFormStore } from '~/store/buy-wizard-form';
import { useErrorStore } from '~/store/error';
import countriesJson from '~/components/nnw/wizard/common/countries.json';

export const useVModelState = (model: any, emit: any) => {
  const state = computed({
    get: () => model,
    set: val => emit('update:modelValue', val),
  });
  return { state };
};

export const formatDate = (value: string | null) => {
  if (value === null || value.replace(/\s+/g, '') === '---') { return ' --- '; }
  if (value) {
    return dayjs(value).format('DD-MM-YYYY');
  }
};

export const capitalizeFirstLetterOfEachWord = (str: string) => {
  const arr = str.split(' ');
  for (let i = 0; i < arr.length; i++) {
    arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
  }

  return arr.join(' ');
};

export const getEmailFromI18n = (type = 'info') => {
  const { t } = useI18n();
  let emailValue;

  if (type === 'info') {
    emailValue = t('common.unilinkInfo.email');
  }

  if (type === 'validation') {
    emailValue = t('validations.email');
  }

  const email = ref(emailValue.replace('&commat;', '@'));

  return {
    email,
  };
};

export const checkDiscounted = (variant) => {
  const BuyWizardFormStore = useBuyWizardFormStore();
  const discountCodeActivated = computed(() => BuyWizardFormStore.getDiscountCodeActivated);
  return discountCodeActivated.value === true ? variant.discounted_price_formatted : variant.price_formatted;
};

export const clearStateByKey = (state, key, directState = false) => {
  directState
    ? state[key] = ''
    : state.value[key] = '';
};

export const clearRef = (ref, type) => {
  if (type === 'array') {
    ref.value = [];
  }
};

export const obtainCityFromZipCode = (zipCode, cities, ref) => {
  const errorStore = useErrorStore();
  const id = ref.value.id;
  errorStore.filterErrorZipCode(id);

  if (zipCode.length === 6) {
    axios.post(`${import.meta.env./api}/v1/city`, {
      postal_code: zipCode,
    }).then((res) => {
      if (res && res.data.data) {
        cities.value = res.data.data;

        if (res.data.data.length === 1) {
          ref.value.select(res.data.data[0]);
        }

        if (res.data.data.length > 1) {
          ref.value.activate();
        }

        if (res.data.data.length === 0) {
          errorStore.pushErrorZipCode({
            isError: true,
            type: `${id}-not-found`,
          });
        }
      }
    }).catch((error) => {
      console.error(error);
    });
  }
};

export const handleHoverFromOther = (hover, value) => {
  hover.value = value;
};

export const toggleMobileFromOutside = ($event, windowWidth, lg, isMobile) => {
  if (windowWidth.value < lg.value
    && !$event.target.classList.contains('insurances-mobile-menu')
    && !$event.target.classList.contains('menu__link')
    && !$event.target.classList.contains('normal-menu')
    && !$event.target.classList.contains('menu__link--category')
  ) {
    isMobile.value = false;
  }
};

export const setErrorMessage = (error, type) => {
  const errorStore = useErrorStore();
  errorStore.setErrorReq({
    status: error.response?.status || '',
    statusText: error.response?.statusText || '',
    message: error.response?.data.message || '',
    errors: error.response?.data.errors || '',
    type,
  });
};

export const getCountryNameFromCode = (country_code) => {
  return countriesJson.some(v => v.code === country_code) ? countriesJson.find(v => v.code === country_code).name : '---';
};

export const clearMultiselectAutocomplete = (multiselectRef) => {
  if (multiselectRef.value && multiselectRef.value.$refs.search) {
    multiselectRef.value.$refs.search && multiselectRef.value.$refs.search.setAttribute('autocomplete', 'do-not-autocomplete');
  }
};

export const handleCityErrorByZipCode = (errorFromZipCode, v$) => {
  if (errorFromZipCode.some(v => (v.isError && v.type === 'nnw-school-insurer-city-not-found') || (v.isError && v.type === 'cp-user-city'))) {
    v$.value.city.$touch();
    v$.value.zipCode.$touch();
  }
  if (errorFromZipCode.some(v => (v.isError && v.type === 'nnw-school-insurer-correspondenceCity-not-found') || (v.isError && v.type === 'cp-user-correspondenceCity'))) {
    v$.value.correspondenceCity.$touch();
    v$.value.correspondenceZipCode.$touch();
  }
};

export const handleCityErrorByZipCodeInsured = (errorFromZipCode, v$, index = 0) => {
  if (errorFromZipCode.some(v => v.isError && v.type === `nnw-school-insured-${index}-city-not-found`)) {
    v$.value.city.$touch();
    v$.value.zipCode.$touch();
  }
};

export const getQuestionCountByCategory = (questionsArray) => {
  return questionsArray.length === 1 ? 3 : 1;
};
