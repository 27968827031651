// ---------------------------------------
// STORE FOR HANDLING AGENT (AGENT PANEL)
// ---------------------------------------
import type { Ref } from 'vue';
import axios from 'axios';
import { acceptHMRUpdate, defineStore } from 'pinia';
import type { IAgent } from '~/types/agent';

export const useAgentStore = defineStore('agent', () => {
  const agent: Ref<IAgent> = ref({
    id: 1,
    name: null,
    last_name: null,
    phone: null,
    email: null,
    code: 'AA123123',
    website: null,
    permissions: [
      'nnw-school',
    ],
    gender: 'N',
  });

  const token = ref('');
  const isLoggedIn: Ref<Boolean> = ref(false);

  const login = async (sid: string) => {
    try {
      const response = await axios.post(`${import.meta.env./api}/v1/agent/auth/login`, { sid });
      agent.value = response.data.data;
      token.value = response.data.access_token;
      isLoggedIn.value = true;
    } catch (error) {
      token.value = '';
      isLoggedIn.value = false;
      console.error(error);
    }
  };

  const logout = async () => {
    try {
      await axios.post(`${import.meta.env./api}/v1/agent/auth/logout`, {});
      token.value = '';
      isLoggedIn.value = false;
    } catch (error) {
      console.error(error);
    }
  };

  const fetchAgentData = async () => {
    try {
      const response = await axios.get(`${import.meta.env./api}/v1/agent/agent`);
      agent.value = response.data.data;
    } catch (error) {
      console.error(error);
    }
  };

  const updateAgentData = (data: object) => {
    return axios.post(`${import.meta.env./api}/v1/agent/agent`, data);
  };

  return {
    agent,
    token,
    isLoggedIn,
    login,
    logout,
    fetchAgentData,
    updateAgentData,
  };
}, {
  persist: {
    storage: sessionStorage,
  },
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useAgentStore, import.meta.hot));
}
